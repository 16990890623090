.dashboard-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.hero-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://i.picsum.photos/id/1059/200/300");
  height: 50%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.deleted-branch{
  opacity: 0.5;
  border-color: #ffd6d6;
  &:hover {
    border: 1px solid #ff6c6c;
    box-shadow: 5px 10px 10px 0 rgba(0, 0, 0, 0.3);
  }
}
