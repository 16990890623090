.transaction-vertical-buttons{
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 15px;
  max-height: 80vh;

  &>.button{
    max-height: 20%;
  }
}

.transaction-shared-title{
  justify-content: space-between;
  align-items: center;
}

.sale-right-panel{
  display: flex !important;
  flex-direction: column;
  row-gap: 15px;
}

.product-list-item{
  cursor: pointer;
  padding: 8px;
  align-items: center;
  color: black;

  .pl-8{
    padding-left: 8px;
  }

  &:hover{
    background-color: #f0f2f5;
  }

  &.green{
    background-color: #d5ffd5;

    &:hover{
      background-color: #bee3be;
    }
  }
  &.error{
    color: red;
  }
  &.success{
    color: green;
  }
}

.product-list-header{
  padding: 10px;
  font-weight: bold;
  text-align: left;
  border-bottom: 1px solid black;
  font-size: 18px;
}

.center{
  text-align: center;
}

.right{
  text-align: right;
}

.total-title{
  margin-bottom: 30px !important;
}

.sales-form{
  width: 100%;
  margin: 0 15px !important;
}
