.user-list-item{
  cursor: pointer;
  padding: 8px;
  align-items: center;
  color: black;

  .pl-8{
    padding-left: 8px;
  }

  &:hover{
    background-color: #f0f2f5;
  }

  &.green{
    background-color: #d5ffd5;

    &:hover{
      background-color: #bee3be;
    }
  }

}

.user-list-header{
  padding: 10px;
  font-weight: bold;
  text-align: left;
  border-bottom: 1px solid black;
  font-size: 18px;
}
