.orders-list-header {
  padding: 10px;
  font-weight: bold;
  text-align: left;
  border-bottom: 1px solid black;
  font-size: 18px;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.order-list-item {
  cursor: pointer;
  padding: 8px;

  &:hover {
    background-color: #f0f2f5;
  }

}

.orders-process-button {
  background-color: green;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  width: 100%;
  height: 75px;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: center;

  &:hover, &:focus{
    background-color: #008000AA;
    color: white;
  }
}

.available-green{
  color: green;
}

.available-red{
  color: red;
}
