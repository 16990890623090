$blue: #465eff;

.h-screen {
  min-height: 100vh !important;
}

.main-layout {
  padding-left: 120px !important;
}

.ant-layout-sider {
  box-shadow: 10px 0 20px 0 rgba(0, 0, 0, 0.3);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 20px !important;

  position: fixed !important;
  min-height: 100% !important;
  z-index: 100 !important;

  &.ant-layout-sider-children {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

.ant-layout-sider-trigger {
  max-width: 50px;
  height: 50px;
  box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  color: white !important;
  background: $blue !important;
  position: absolute !important;
  right: -25px;
  top: calc(100vh - 75px) !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  padding-left: 24px;
  color: white;
  border-radius: 20px;
  background-color: $blue !important;
}

.ant-menu-item-selected a {
  color: white !important;
}

.ant-menu-inline-collapsed {
  width: 50px !important;

  > .ant-menu-item {
    padding: 0 16px !important;
  }
}

.custom-card {
  background-color: white;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  transition: all 500ms ease;

  border-radius: 20px !important;

  & > * {
    border-radius: 20px;
  }

  &:hover {
    border: 1px solid $blue;
    box-shadow: 5px 10px 10px 0 rgba(0, 0, 0, 0.3);
  }

  &.button {
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    flex: 1;
    font-weight: bold;
    font-size: 1.2em;
  }
}

.bg-card {
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 20px !important;

  & > * {
    border-radius: 20px;
  }

}

.flex-1 {
  flex: 1
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.big-icon {
  font-size: 2.5em;
  margin-right: 20px;

  &.button {
    cursor: pointer;
  }
}

.huge-icon {
  font-size: 3.5em;
  margin-right: 20px;

  &.button {
    cursor: pointer;
  }
}

.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}


.green {
  color: green !important;
}

.bg-green {
  background-color: green !important;
  border-color: green !important;

  &[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
    background: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
  }
}

.red {
  color: red !important;
}

.blue {
  color: cornflowerblue !important;
}

.pointer {
  cursor: pointer;
}

.underline {
  text-decoration: underline !important;
}

.upload-result-text {
  font-weight: bold;
  font-size: 14px;

  &.error {
    color: red;
  }

  &.success {
    color: green;
  }
}
